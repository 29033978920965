/* eslint-disable react/no-array-index-key */
import React, {useState, useEffect} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {manageClasses} from '../util/componentsUtil';
import DynamicComponent from './DynamicComponent';

function DynamicInfiniteCarousel({
  items,
  responsiveConfig,
  id,
  updateMiddleItem,
  originModel,
  // dynamicComponent data
  component, dataEndpoints, idGetEndpoint,
  // eslint-disable-next-line no-unused-vars
  objectReader, nameFiledReader, idPostEndpoint,
  idProject, addTokenApp, addUserApp, params, idPutEndpoint,
  idDeleteEndpoint, relationParam, token,
  localState, globalVariables, setLocalState, setGlobalState,
  refurbishEndpoint, variableForEndpoint,
  dataGenerate,
}) {
  /*
    const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items: 5,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 3,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 3,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };
  */
  // const [middleItem, setMiddleItem] = useState(null);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  function handleSetItem(currentSection) {
    let useVisible = 3; // default
    if (screenSize > 3000) {
      useVisible = responsiveConfig.superLargeDesktop.items;
    } else if (screenSize < 3000 && screenSize > 1024) {
      useVisible = responsiveConfig.desktop.items;
    } else if (screenSize < 1024 && screenSize > 464) {
      useVisible = responsiveConfig.tablet.items;
    } else if (screenSize < 464) {
      useVisible = 1;
    }
    const visibleItemsCount = useVisible; // Adjust based on actual current screen width
    const middleIndex = Math.floor(visibleItemsCount / 2);
    const totalItems = items.length;

    let middleIndexInItemsArray = (currentSection + middleIndex) % totalItems;
    if (middleIndexInItemsArray < 0) {
      middleIndexInItemsArray = totalItems + middleIndexInItemsArray;
    }

    const newMiddleItem = items[middleIndexInItemsArray];
    // setMiddleItem(newMiddleItem);

    // Update the parent component with the middle item
    updateMiddleItem(id, newMiddleItem);
  }

  const handleBeforeChange = (nextSlide) => {
    handleSetItem(nextSlide);
  };

  useEffect(() => {
    handleSetItem(0);
  }, [items]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className={manageClasses(originModel)} id={originModel.attributes?.id}>
      <Carousel
        responsive={responsiveConfig}
        beforeChange={handleBeforeChange}
        infinite={!(screenSize < 464)}
      >
        {items?.map((item, index) => (
          <div key={index} id={component.attributes?.id} className={manageClasses(component)}>
            {
              component.components?.map((value) => (
                <DynamicComponent
                  idProject={idProject}
                  component={value}
                  dataEndpoints={dataEndpoints}
                  objectReader={item}
                  params={params}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idGetEndpoint={idGetEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  nameFiledReader={nameFiledReader}
                  relationParam={relationParam}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  token={token}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  // Input With Variables
                  // inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={item}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  dataGenerate={dataGenerate}
                />
              ))
            }
          </div>
        ))}
      </Carousel>
      {/* {middleItem && (
        <div>
          <h2>Middle Item of Carousel {id}</h2>
          <p>{JSON.stringify(middleItem)}</p>
        </div>
      )} */}
    </div>
  );
}

export default DynamicInfiniteCarousel;
