/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import {manageClasses, slugLoftyEqualTo} from '../util/componentsUtil';
import DynamicInfiniteSingleCarousel from './DynamicInfiniteSingleCarousel';

function SingleCarouselDynamic({
  component, dataEndpoints, idGetEndpoint,
  objectReader, nameFiledReader, idPostEndpoint, dataGenerate,
  idProject, addTokenApp, addUserApp, params, idPutEndpoint,
  // eslint-disable-next-line no-unused-vars
  idDeleteEndpoint, relationParam, token, carouselData, carouselComponents,
  localState, globalVariables, setLocalState, setGlobalState,
  refurbishEndpoint, variableForEndpoint,
}) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items: parseInt(component?.desktopItems) || 5,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: parseInt(component?.desktopItems) || 3,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: parseInt(component?.tabletItems) || 3,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: parseInt(component?.mobileItems) || 1,
    },
  };

  if (!objectReader || objectReader.lenght <= 0) {
    return null;
  }
  return (
    <div className={manageClasses(component)} id={component.attributes?.id}>
      {component?.components?.map((model) => {
        if (slugLoftyEqualTo(model, 'itemListEndpointDatabase')) {
          if (!objectReader) {
            return null;
          }
          const itemData = objectReader;
          return (
            <DynamicInfiniteSingleCarousel
              idProject={idProject}
              component={model}
              dataEndpoints={dataEndpoints}
              objectReader={itemData?.data?.data}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              relationParam={relationParam}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              token={token}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              // Input With Variables
              // inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={itemData?.data?.data}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responsiveConfig={responsive}
              items={itemData?.data?.data}
              originModel={model}
              dataGenerate={dataGenerate}
            />
          );
        }
        return null;
      })}
    </div>
  );
}

export default SingleCarouselDynamic;
