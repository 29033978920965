/* eslint-disable react/no-array-index-key */
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {manageClasses} from '../util/componentsUtil';
import DynamicComponent from './DynamicComponent';

function DynamicInfiniteSingleCarousel({
  items,
  responsiveConfig,
  originModel,
  // dynamicComponent data
  component, dataEndpoints, idGetEndpoint,
  // eslint-disable-next-line no-unused-vars
  objectReader, nameFiledReader, idPostEndpoint,
  idProject, addTokenApp, addUserApp, params, idPutEndpoint,
  idDeleteEndpoint, relationParam, token,
  localState, globalVariables, setLocalState, setGlobalState,
  refurbishEndpoint, variableForEndpoint,
  dataGenerate,
}) {
  return (
    <div className={manageClasses(originModel)} id={originModel.attributes?.id}>
      <Carousel
        responsive={responsiveConfig}
        infinite
        autoPlay
      >
        {items?.map((item, index) => (
          <div key={index} id={component.attributes?.id} className={manageClasses(component)}>
            {
              component.components?.map((value) => (
                <DynamicComponent
                  idProject={idProject}
                  component={value}
                  dataEndpoints={dataEndpoints}
                  objectReader={item}
                  params={params}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idGetEndpoint={idGetEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  nameFiledReader={nameFiledReader}
                  relationParam={relationParam}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  token={token}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  // Input With Variables
                  // inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={item}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  dataGenerate={dataGenerate}
                />
              ))
            }
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default DynamicInfiniteSingleCarousel;
