/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React, {useEffect, useState} from 'react';
// import toastr from 'toastr';
import {manageClasses, slugLoftyEqualTo} from '../util/componentsUtil';
import DynamicComponent from './DynamicComponent';

function CartCarouselDynamic({
  component, dataEndpoints, idGetEndpoint,
  nameFiledReader, idPostEndpoint, dataGenerate,
  idProject, addTokenApp, addUserApp, params, idPutEndpoint,
  // eslint-disable-next-line no-unused-vars
  idDeleteEndpoint, relationParam, token,
  localState, globalVariables, setLocalState, setGlobalState,
  refurbishEndpoint, variableForEndpoint,
}) {
  const [variableStateInfo, setVariableStateInfo] = useState([]);
  const [hasMoreThanFive, setHasMoreThanFive] = useState(false);
  const [currentRendered, setCurrentRendered] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(0);
  const [currentItem, setCurrentItem] = useState([]);
  function searchMyValue(id, infoData) {
    // console.log(id);
    const resData = infoData?.find((index) => (index._id === id));
    if (resData?.state) {
      return resData.state;
    }
    return '';
  }

  useEffect(() => {
    let dataState = [];
    if (component?.localVars) {
      dataState = searchMyValue(component.localVars, localState);
    } else if (component?.globalVars) {
      dataState = searchMyValue(component.globalVars, globalVariables);
    }
    let currentStateForRender = dataState;
    if (dataState?.length > 5) {
      setHasMoreThanFive(true);
      currentStateForRender = dataState.slice(startIndex, 5);
      setLowerLimit(0);
      setUpperLimit(5);
    }
    setCurrentItem(dataState[startIndex]);
    setCurrentRendered(currentStateForRender);
    setVariableStateInfo(dataState);
  }, []);

  function nextItemCarousel() {
    if (startIndex + 1 >= variableStateInfo.length) {
      return null;
    }
    setCurrentItem(variableStateInfo[startIndex + 1]);
    if (hasMoreThanFive && startIndex + 1 >= upperLimit) {
      const updateCurrentRender = variableStateInfo.slice(lowerLimit + 5, upperLimit + 5);
      setLowerLimit(lowerLimit + 5);
      setUpperLimit(upperLimit + 5);
      setCurrentRendered(updateCurrentRender);
    }
    setStartIndex(startIndex + 1);
  }

  function previousItemCarousel() {
    if (startIndex - 1 < 0) {
      return null;
    }
    setCurrentItem(variableStateInfo[startIndex - 1]);
    if (hasMoreThanFive && startIndex - 1 < lowerLimit) {
      const updateCurrentRender = variableStateInfo.slice(lowerLimit - 5, upperLimit - 5);
      setLowerLimit(lowerLimit - 5);
      setUpperLimit(upperLimit - 5);
      setCurrentRendered(updateCurrentRender);
    }
    setStartIndex(startIndex - 1);
  }

  return (
    <div className={manageClasses(component)} id={component.attributes?.id}>
      {variableStateInfo?.length > 0 && (
        component?.components?.map((sections) => {
          if (slugLoftyEqualTo(sections, 'cartCarouselListSection')) {
            return (
              <div className={manageClasses(sections)} id={sections.attributes?.id}>
                {currentRendered.map((item) => (
                  <div
                    style={{opacity: item?.cartitem?._id === currentItem?.cartitem?._id ? 1 : 0.2}}
                  >
                    <img src={item?.cartitem?.loftyUrl} alt="img" style={{height: '100px', objectFit: 'contain'}} />
                  </div>
                ))}
              </div>
            );
          }
          if (slugLoftyEqualTo(sections, 'showCartItemImage')) {
            return (
              <div className={manageClasses(sections)} id={sections.attributes?.id}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <img src={currentItem?.cartitem?.loftyUrl} alt="img" style={{height: '500px', objectFit: 'contain'}} />
                </div>
                <div style={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%',
                }}
                >
                  <div style={{fontSize: '32px', cursor: 'pointer'}} role="button" tabIndex={0} onClick={previousItemCarousel}>
                    &#8678;
                  </div>
                  <div style={{fontSize: '32px', cursor: 'pointer'}} role="button" tabIndex={0} onClick={nextItemCarousel}>
                    &#8680;
                  </div>
                </div>
              </div>
            );
          }
          if (slugLoftyEqualTo(sections, 'cartItemInfoRender')) {
            const getComponentItem = sections?.components?.find((index) => (slugLoftyEqualTo(index, 'itemCartData')));
            return (
              <div className={manageClasses(sections)} id={sections.attributes?.id}>
                <div
                  className={manageClasses(getComponentItem)}
                  id={getComponentItem.attributes?.id}
                >
                  {
                  getComponentItem?.components?.map((item) => {
                    if (item.sluglofty === 'quantityItemCartData') {
                      const searchForContent = item?.components?.find((index) => (index.type === 'textnode'));
                      return (
                        <div className={manageClasses(item)} id={item.attributes?.id}>
                          {`${searchForContent?.content || 'qty:'} ${currentItem.quantity}`}
                        </div>
                      );
                    }
                    return (
                      <DynamicComponent
                        idProject={idProject}
                        component={item}
                        dataEndpoints={dataEndpoints}
                        objectReader={currentItem.cartitem}
                        params={params}
                        idDeleteEndpoint={idDeleteEndpoint}
                        idGetEndpoint={idGetEndpoint}
                        idPostEndpoint={idPostEndpoint}
                        idPutEndpoint={idPutEndpoint}
                        nameFiledReader={nameFiledReader}
                        relationParam={relationParam}
                        deletetokenApp={[]}
                        dataGenerate={dataGenerate}
                        // plugin del calnedario
                        activeMonth={[]}
                        changeLastMonth={[]}
                        changeNextMonth={[]}
                        listHours={[]}
                        now={[]}
                        selectDate={[]}
                        changeHour={[]}
                        submitCalendar={[]}
                        addTokenApp={addTokenApp}
                        addUserApp={addUserApp}
                        user={[]}
                        useCurrentUserAddDatabase={[]}
                        token={token}
                        allowMultipleAttachment={[]}
                        idGetEnpointSwiper1={[]}
                        idGetEnpointSwiper2={[]}
                        idGetEnpointSwiper3={[]}
                        relationParamData={[]}
                        userInfo={[]}
                        databaseSelectionForFields={[]}
                        // STATES
                        globalVariables={globalVariables}
                        setGlobalState={setGlobalState}
                        localState={localState}
                        setLocalState={setLocalState}
                        // Input With Variables
                        inputStateVariable={[]}
                        // For Read Db with dbField data
                        rootReaderData={currentItem.cartitem}
                        refurbishEndpoint={refurbishEndpoint}
                        variableForEndpoint={variableForEndpoint}
                      />
                    );
                  })
                }
                </div>
              </div>
            );
          }
          return null;
        })
      )}
    </div>
  );
}

export default CartCarouselDynamic;
